import React, {FC, useState} from 'react';
import {Eye, EyeSlash} from "@phosphor-icons/react";


interface HideOwnRecipesProps {
    showRecipes: any
    hideRecipes: any
}

const HideOwnRecipes: FC<HideOwnRecipesProps> = (props) => {

    const [used, setUsed] = useState(false);

    const hideOwnRecipes = () => {
        if(used)
        {
            props.showRecipes();
            setUsed(false);
        } else {
            props.hideRecipes();
            setUsed(true);
        }
    }

    return (
        <div
            onClick={() => hideOwnRecipes()}
            className="text-sm p-2 flex items-center border-[1px] border-content3 dark:bg-[#19191acc]
                text-black dark:text-white rounded-xl h-16 relative"
        >
            <div className="ml-2 flex flex-col">
                <i className="text-sm">Hide my recipes</i>
            </div>
            <div className="absolute bottom-0 right-0 mr-2 mb-2">
                {!used ? <Eye size={20}/> : <EyeSlash size={20}/>}
            </div>
        </div>
    );
}

export default HideOwnRecipes;
