import React, { FC, useEffect, useRef, useState } from "react";
import RecipeGridView from "../components/RecipeGridView/RecipeGridView";
import { Pagination } from "@nextui-org/react";
import DropdownFilter from "../components/FilterComponents/DropdownFilter/DropdownFilter";
import InputFilter from "../components/FilterComponents/InputFilter/InputFilter";
import { useAuth0 } from "@auth0/auth0-react";
import ShareService from "../services/ShareService";
import RecipeService from "../services/recipeService";
import { Recipe } from "../models/Recipe";
import { AllowedBooksDto } from "../models/AllowedBooksDto";
import { FilterProvider } from "../components/FilterContext/FilterContext";
import SharedRecipesBooks from "../components/SharedRecipesBooks/SharedRecipesBooks";
import HideOwnRecipes from "../components/HideOwnRecipes/HideOwnRecipes";

const Recipes: FC = () => {
    const { getAccessTokenSilently } = useAuth0();
    const token = useRef("");
    const [allRecipes, setAllRecipes] = useState<Recipe[]>([]); // The main state for displayed recipes
    const [ogRecipes, setOgRecipes] = useState<Recipe[]>([]); // Original recipes fetched from RecipeService
    const [sharedRecipes, setSharedRecipes] = useState<Recipe[]>([]); // Shared recipes fetched from ShareService
    const [allowedBooks, setAllowedBooks] = useState<AllowedBooksDto[]>([]);
    const [filterToggleState, setFilterToggleState] = useState<boolean | null>(null);

    // Fetch original recipes
    const getRecipes = async () => {
        try {
            const service = new RecipeService();
            const recipeArray = await service.getRecipes(token.current);

            setOgRecipes(recipeArray); // Store original recipes
            setAllRecipes(recipeArray); // Initially display only original recipes
        } catch (error) {
            console.error("Error fetching original recipes:", error);
        }
    };

    // Fetch shared recipes
    const getSharedRecipes = async () => {
        try {
            const shareService = new ShareService();
            const books = await shareService.GetAllowedBooks(token.current);

            setAllowedBooks(books); // Store allowed books

            // Flatten shared recipes
            const sharedRecipes = books.flatMap(book => book.recipes);
            setSharedRecipes(sharedRecipes); // Store shared recipes (for debugging or reuse)
        } catch (error) {
            console.error("Error fetching shared recipes:", error);
        }
    };

    // Merge and deduplicate recipes
    const mergeAndDeduplicateRecipes = () => {
        const combinedRecipes = [...ogRecipes, ...sharedRecipes];
        const deduplicatedRecipes = Array.from(
            new Map(combinedRecipes.map(recipe => [recipe.title, recipe])).values()
        );
        setAllRecipes(deduplicatedRecipes); // Update the displayed recipes
    };

    // Fetch data on component mount
    useEffect(() => {
        const initialize = async () => {
            if (filterToggleState == null && window.innerWidth >= 640) {
                setFilterToggleState(true);
            }

            const accessToken = await getAccessTokenSilently();
            token.current = accessToken;

            await getRecipes(); // Fetch original recipes
            await getSharedRecipes(); // Fetch shared recipes
        };

        initialize();
    }, [getAccessTokenSilently]);

    // Merge recipes whenever original or shared recipes change
    useEffect(() => {
        mergeAndDeduplicateRecipes();
    }, [ogRecipes, sharedRecipes]);

    const getCourseOptions = () => {
        const courses = new Set(
            allRecipes.map(recipe => recipe.course.toLowerCase()) // Use allRecipes
        );
        return Array.from(courses);
    };

    const getCategoryOptions = () => {
        const categories = new Set(
            allRecipes.map(recipe => recipe.category.toLowerCase()) // Use allRecipes
        );
        return Array.from(categories);
    };

    const getTimeOptions = () => {
        return Object.freeze(["< 20 minutes", "< 30 minutes", "< 1 hour", "< 2 hours"]);
    };

    const showRecipes = () => {
        setAllRecipes(addOgRecipesBack(allRecipes, ogRecipes));
    }

    const addOgRecipesBack = (allRecipes: Recipe[], ogRecipes: Recipe[]): Recipe[] => {
        const ogRecipeIds = new Set(ogRecipes.map(recipe => recipe.id));
        const filteredAllRecipes = allRecipes.filter(recipe => !ogRecipeIds.has(recipe.id));
        return [...ogRecipes, ...filteredAllRecipes];
    };

    const subtractOgRecipes = (allRecipes: Recipe[], ogRecipes: Recipe[]): Recipe[] => {
        const ogRecipeIds = new Set(ogRecipes.map(recipe => recipe.id));
        return allRecipes.filter(recipe => !ogRecipeIds.has(recipe.id));
    };

    const hideRecipes = () => {
        setAllRecipes(subtractOgRecipes(allRecipes, ogRecipes));
    }

    return (
        <div>
            <FilterProvider>
                <div className="container mx-auto">
                    <div className="flex gap-2 pt-4">
                        <DropdownFilter
                            defaultButtonValue="Category"
                            ariaLabel="Category selection"
                            recipeFilterString="category"
                            options={getCategoryOptions()}
                        />
                        <DropdownFilter
                            defaultButtonValue="Course"
                            ariaLabel="Course selection"
                            recipeFilterString="course"
                            options={getCourseOptions()}
                        />
                        <InputFilter />
                    </div>
                    <div className="grid mt-8 mb-6 gap-[2vw] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                        <HideOwnRecipes showRecipes={showRecipes} hideRecipes={hideRecipes} />
                        <SharedRecipesBooks shared={allowedBooks} />
                    </div>
                    <RecipeGridView recipes={allRecipes} className="py-4" />
                    <Pagination showControls total={10} initialPage={1} />
                </div>
            </FilterProvider>
        </div>
    );
};

export default Recipes;
