import React, {FC, useState} from 'react';
import {AllowedBooksDto} from "../../models/AllowedBooksDto";
import {useFilter} from "../FilterContext/FilterContext";
import {Eye, EyeSlash} from "@phosphor-icons/react";

interface SharedRecipesBooksProps {
    shared?: AllowedBooksDto[];
}

const SharedRecipesBooks: FC<SharedRecipesBooksProps> = (props) => {

    const {filters, setFilters } = useFilter();

    const [used, setUsed] = useState(false);

    const handleFilterChange = (filterName: string, value: any) => {
        if (!used) {
            setUsed(true);
            setFilters({ ...filters, [filterName]: [...(filters[filterName] || []), value] });
        } else {
            setUsed(false);
            const updatedValues = (filters[filterName] || []).filter((item: any) => item !== value);
            setFilters({ ...filters, [filterName]: updatedValues });
        }
    };

    const handleTitleFilterChange = (r: AllowedBooksDto) => {
        handleFilterChange("books", r.ownerId);
    };

    if(props?.shared?.length == 0)
        return (<></>);

    return (
        <div>
            {props?.shared?.map((r, index) => (
                <div
                    onClick={() => handleTitleFilterChange(r) }
                    key={index}
                    className="text-sm p-2 flex items-center border-[1px] border-content3 dark:bg-[#19191acc]
                    text-black dark:text-white rounded-xl h-16 relative"
                >
                    <div className="flex-shrink-0 w-[32px]">
                        <img className="w-[32px] h-[32px] rounded-2xl" src={r.profileImage}
                             alt="Profile"/>
                    </div>
                    <div className="ml-2 flex flex-col">
                        <i className="text-sm">{r.email}</i>
                        <span>({r.recipes.length} shared recipes)</span>
                    </div>
                    <div className="absolute bottom-0 right-0 mr-2 mb-2">
                        {!used ? <Eye size={20}/> : <EyeSlash size={20} /> }
                    </div>

                </div>
            ))}
        </div>
    )
};
export default SharedRecipesBooks;
